/* stylelint-disable */
@use 'moyo/abstracts/color';
@use '@angular/material' as mat;
@use './variables/font.variables';

@include mat.core();

// Have to set custom palette in order to use own colors across all angular material framework
$dark-primary-text: color.$pebble-1000;
$light-primary-text: color.$pebble-0;

$cp-primary: (
  50: rgba(color.$primary-400, 70%),
  100: rgba(color.$primary-400, 50%),
  200: rgba(color.$primary-400, 40%),
  300: rgba(color.$primary-400, 20%),
  400: rgba(color.$primary-400, 10%),
  500: color.$primary-400,
  600: color.$primary-400,
  700: color.$primary-400,
  800: color.$primary-600,
  900: color.$primary-600,
  A100: rgba(color.$primary-400, 20%),
  A200: color.$primary-400,
  A400: color.$primary-400,
  A700: color.$primary-600,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$cp-accent: (
  50: rgba(color.$primary-400, 70%),
  100: rgba(color.$primary-400, 50%),
  200: rgba(color.$primary-400, 40%),
  300: rgba(color.$primary-400, 20%),
  400: rgba(color.$primary-400, 10%),
  500: color.$primary-400,
  600: color.$primary-400,
  700: color.$primary-400,
  800: color.$primary-600,
  900: color.$primary-600,
  A100: rgba(color.$primary-400, 20%),
  A200: color.$primary-400,
  A400: color.$primary-400,
  A700: color.$primary-600,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$cp-typography: mat.m2-define-typography-config(
  $font-family: font.$cp-font-family-primary,
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once
@include mat.typography-hierarchy($cp-typography);
@include mat.core();

$candy-app-primary: mat.m2-define-palette($cp-primary);
$candy-app-accent: mat.m2-define-palette($cp-accent);
$candy-app-warn: mat.m2-define-palette(mat.$m2-red-palette);
$candy-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $candy-app-primary,
      accent: $candy-app-accent,
      warn: $candy-app-warn,
    ),
    density: 0,
  )
);

@include mat.all-component-themes($candy-app-theme);
