@use 'color';
@use 'typography';

.ui-autocomplete {
  mat-option {
    padding: 0.75rem 1rem;
  }

  &__show-more-divider {
    margin: 0.75rem 0;
    height: 1px;
    background: color.$pebble-200;
  }

  &__show-more {
    @include typography.subtitle-2;

    color: color.$primary-400;
    display: flex;
    border: none;
    background: none;
    width: 100%;
    height: 2.5rem;
    margin: 0.75rem 0 0.25rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover:not(:disabled) {
      background: color.$primary-100;
    }

    &--loading {
      background: color.$pebble-200;
      color: color.$pebble-500;
    }
  }
}
