$cp-table-icon-margin: 24px;
$cp-table-action-height: 2.5rem;

.cp-table__filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;

  .cp-form {
    flex-grow: 1;
    margin-bottom: 0;
  }

  .cp-paginator {
    margin-left: auto;
    display: flex;
  }

  &--action {
    margin-left: 0.5rem;
    height: $cp-table-action-height;
    display: flex;
    align-items: center;
  }
}
