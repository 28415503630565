@use 'color';
@use 'libs/styles/variables/variables';

.cp-selection {
  &__list.mat-selection-list {
    padding: 0;
    font-weight: variables.$cp-font-weight-regular;
    font-family: variables.$cp-font-family-primary;
    letter-spacing: 0.5px;
  }

  &__option--selected {
    background-color: color.$primary-100;
  }
}
