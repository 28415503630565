/*
 * Deprecated colors
 * Use `@use 'libs/styles/moyo/abstracts/typography'` instead
 */
$cp-font-family-primary: 'Inter', 'Helvetica Neue', sans-serif;
$cp-font-family-secondary: 'Poppins', 'Helvetica Neue', sans-serif;

$cp-font-weight-bold: 700;
$cp-font-weight-semibold: 600;
$cp-font-weight-medium: 500;
$cp-font-weight-regular: 400;
