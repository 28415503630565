@use 'color';
@use 'typography';
@use 'libs/styles/variables/variables';

$cp-input-height: 3rem;
$cp-input-line-height: 1.5rem;
$cp-input-line-height-assistive: 1rem;
$cp-input-horizontal-padding: 1rem;
$cp-input-vertical-padding: 0.75rem;

$cp-input-font-size: 1rem;
$cp-input-font-size-assistive: 0.75rem;
$cp-input-font-family: variables.$cp-font-family-primary;
$cp-input-font-weight: variables.$cp-font-weight-regular;

$cp-input-color: color.$pebble-800;
$cp-input-color-assistive: color.$pebble-600;
$cp-input-color-focused: color.$primary-400;
$cp-input-color-disabled: color.$pebble-500;
$cp-input-border-width: 1px;
$cp-input-margin-top: $cp-input-border-width * 2;
$cp-input-textarea-min-height: 2.5rem;
$cp-input-textarea-padding-right: 0.5rem;

@mixin cp-input {
  --mat-form-field-error-text-color: #{color.$blush-600};
  --mdc-outlined-text-field-error-label-text-color: #{color.$blush-600};
  --mdc-outlined-text-field-error-focus-label-text-color: #{color.$blush-600};
  --mdc-outlined-text-field-error-focus-outline-color: #{color.$blush-600};
  --mdc-outlined-text-field-error-hover-outline-color: #{color.$blush-600};
  --mdc-outlined-text-field-error-outline-color: #{color.$blush-600};
  --mat-form-field-container-height: 2.75rem;

  .mat-mdc-floating-label {
    --mat-mdc-form-field-label-offset-x: 0.5rem;

    @include typography.body-1;

    color: $cp-input-color-assistive;
    top: 22px;

    &.mdc-floating-label--float-above {
      --mat-mdc-form-field-label-transform: translateY(-29.75px)
        scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    }

    mat-label {
      white-space: nowrap;
    }
  }

  /**
   * This can also be disabled by setting the hideRequiredMarker property on <mat-form-field subscriptSizing="dynamic" >, but
   * that would require adding this property to each <mat-form-field subscriptSizing="dynamic" > as UX always wants it hidden.
   */
  .mat-mdc-form-field-required-marker {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 1rem;
    margin: 0.5rem 0;
  }

  .mat-mdc-form-field-icon-prefix {
    padding-right: 16px;
  }

  .mat-mdc-form-field-infix {
    max-height: 3rem;
    border-top: 0.75rem solid transparent;
    line-height: 1.5rem;
    padding: 0 0 0.75rem;
    width: 100%;
  }

  .mat-mdc-select-arrow-wrapper {
    transform: none;
  }

  .mat-mdc-select-arrow {
    margin: 0;
    border-left: 1.5rem solid transparent;
    border-right: none;
    border-top: 1.5rem solid transparent;
    border-image-source: url('/assets/icons/chevron-down.svg');
    border-image-repeat: stretch;

    svg {
      display: none;
    }
  }

  .mat-mdc-select-value {
    color: $cp-input-color;
  }

  .mat-mdc-select-value-text {
    font-size: $cp-input-font-size;
    color: $cp-input-color;
  }

  .mat-mdc-form-field-flex {
    max-height: $cp-input-height;
    padding: 0;
  }

  .mat-mdc-form-field-subscript-wrapper {
    @include typography.body-3;

    margin-top: -4px;
    padding: 0;
    min-height: 20.5px;
    color: color.$pebble-600;
  }

  .mat-mdc-input-element,
  .mat-mdc-select {
    border: none;
    font-family: $cp-input-font-family;
    caret-color: $cp-input-color;
    color: $cp-input-color;
    line-height: $cp-input-line-height;
    font-weight: $cp-input-font-weight;
    font-size: $cp-input-font-size;
  }

  .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: color.$pebble-600;
      border-width: 1px;
    }
  }

  &.mat-focused {
    .mat-mdc-floating-label {
      color: $cp-input-color-focused;
    }

    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $cp-input-color-focused;
      }
    }
  }

  &.mat-form-field-disabled {
    .mat-mdc-floating-label,
    .mdc-notched-outline,
    .mat-mdx-hint,
    .mat-mdc-input-element,
    .mat-select,
    .mat-select-value-text,
    .mat-form-field-suffix {
      color: $cp-input-color-disabled;

      --ui-icon-color: #{color.$pebble-400};
    }

    .mat-select-arrow {
      opacity: 0.5;
    }

    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: color.$pebble-400;
      }
    }
  }

  &.cp-textarea {
    .mat-mdc-form-field-flex,
    .mat-mdc-form-field-infix {
      max-height: none;
    }
  }

  .cp-input__button {
    position: absolute;
    top: 0;
    right: -$cp-input-horizontal-padding;
  }

  .cp-input__warning {
    color: color.$sunset-600;
  }
}

@mixin cp-input-compact {
  margin-bottom: 0;

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .mat-mdc-form-field-subscript-wrapper {
    min-height: 0;
  }
}

@mixin cp-input-no-borders {
  .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-width: 0;
    }
  }

  &.mat-mdc-form-field-appearance-outline {
    .mat-mdc-form-field-flex {
      padding: 0 16px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      padding: 0;
    }
  }
}

@mixin cp-input-custom-size(
  $max-height: $cp-input-height,
  $min-height: $cp-input-height,
  $horizontal-padding: $cp-input-horizontal-padding,
  $input-vertical-padding: $cp-input-vertical-padding,
  $width: 100%
) {
  width: $width;

  &.mat-mdc-form-field-appearance-outline {
    .mat-mdc-form-text-infix {
      min-height: $min-height;
      max-height: $max-height;
      border-top: $input-vertical-padding solid transparent;
      padding: 0 0 $input-vertical-padding;
    }

    .mat-mdc-form-field-flex {
      min-height: $min-height;
      max-height: $max-height;
      padding: 0 $horizontal-padding;
    }

    .mat-mdc-form-field-subscript-wrapper {
      padding: 0 $horizontal-padding;
    }
  }

  .cp-input__button {
    top: -$input-vertical-padding - $cp-input-line-height;
    right: -$horizontal-padding;
  }
}

@mixin cp-input-small {
  min-width: 9.25rem;
  max-width: 13.625rem;
}

@mixin cp-input-medium {
  max-width: 28.25rem;
}

@mixin cp-input-tiny {
  width: 4.5rem;
  margin-bottom: 0;

  .mat-mdc-text-field-wrapper {
    padding: 0 0.5rem;
    height: 2rem;
  }

  .mat-mdc-form-field-infix {
    border: none;
    min-height: 2rem;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .mat-mdc-form-field-subscript-wrapper {
    min-height: 0;
    display: none;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline {
    height: 2rem;
  }

  .mat-pseudo-checkbox {
    display: none;
  }
}
