@use 'color';
@use 'elevation';
@use 'typography';

.ui-tooltips {
  background: color.$pebble-1000;
  padding: 0.75rem 1rem;
  max-width: 25rem;
  @include elevation.level-1;

  border-radius: 0.25rem;

  &__title {
    @include typography.subtitle-2;

    color: color.$pebble-400;

    &-new-line {
      display: block;
    }
  }

  &__body {
    @include typography.body-2;

    color: color.$pebble-0;
  }

  :hover {
    cursor: pointer;
  }
}
