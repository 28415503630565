@use 'color';
@use 'libs/styles/variables/variables';
@use 'libs/ui/src/lib/input/input.variables';

.cp-textarea {
  margin-bottom: variables.$cp-margin-small;
  margin-top: input.$cp-input-border-width * 2;

  &.mat-mdc-form-field {
    @include input.cp-input;

    width: 100%;

    &.mat-mdc-form-field-appearance-outline {
      .mat-mdc-form-field-infix {
        max-height: none;
      }

      .mat-mdc-form-field-flex {
        max-height: none;
        padding-right: input.$cp-input-textarea-padding-right;
      }
    }

    textarea {
      min-height: input.$cp-input-textarea-min-height;
    }

    .cp-textarea__hint--error {
      color: color.$blush-600;
    }
  }

  &--no-borders.mat-mdc-form-field.mat-mdc-form-field-appearance-outline {
    @include input.cp-input-no-borders;
  }
}
