@use 'color';
@use 'elevation';
@use 'libs/styles/variables/variables';
@use 'libs/ui/src/lib/input/input.variables';

.cp-paginator {
  margin-left: auto;
  height: 2rem;
  position: relative;

  .mat-mdc-paginator-outer-container {
    padding: 0;
  }

  .mat-mdc-paginator-container {
    height: 2rem;
    padding: 0;
    margin: 0;
  }

  .mat-mdc-paginator-range-label,
  .mat-mdc-paginator-page-size-label {
    color: color.$pebble-800;
    font-family: variables.$cp-font-family-primary;
    font-weight: variables.$cp-font-weight-medium;
    font-size: 16px;
    line-height: 24px;
  }

  .mat-mdc-paginator-range-label {
    margin: 0 variables.$cp-margin-medium;
  }

  .mat-mdc-paginator-page-size {
    margin: 0;
  }

  .mat-mdc-paginator-page-size-label {
    margin-right: variables.$cp-margin-small;
  }

  .mat-mdc-paginator-navigation-previous {
    margin-right: variables.$cp-margin-small;
    background-image: url('/assets/icons/chevron-left.svg');
  }

  .mat-mdc-paginator-navigation-next {
    background-image: url('/assets/icons/chevron-right.svg');
  }

  .mat-mdc-paginator-navigation-previous,
  .mat-mdc-paginator-navigation-next {
    padding: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &::before {
      content: '';
      background-size: 100%;
      width: 100%;
      height: 100%;
      display: block;
    }

    .mat-mdc-paginator-icon {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:focus:not(:disabled) {
      @include elevation.level-0;

      background-color: color.$pebble-200;
    }
  }

  /*
   * We can not add classes such as cp-input to this form field, which is why we have to re-overwrite the classes
   * directly.
   */
  .mat-mdc-form-field.mat-form-field-appearance-outline {
    @include input.cp-input;
    @include input.cp-input-compact;
  }
}
