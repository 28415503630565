@use 'color';
@use 'libs/styles/variables/variables';

.cp-action-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 260px;
  background-color: color.$pebble-0;
  box-shadow: 0 -8px 8px rgba(color.$pebble-1000, 0.1); /* stylelint-disable-line */
  padding: variables.$cp-margin-large 2.5rem;
  display: flex;
  align-items: center;
  z-index: 1002;

  @media screen and (max-width: variables.$cp-breakpoint-tablet) {
    left: 88px;
  }

  button[ui-button] {
    margin-right: variables.$cp-margin-button;
  }

  &-table-container {
    margin-bottom: variables.$cp-margin-giant;
  }

  &__action {
    &--right {
      margin-left: auto;
    }
  }

  &--with-scroll {
    bottom: 1rem;
    right: 1rem;
  }
}
