$title-typeface: 'Poppins', sans-serif;
$default-typeface: 'Inter', sans-serif;
$base-size: 16px;
$regular: 400;
$semibold: 600;
$bold: 700;

@mixin title-1 {
  font-family: $title-typeface;
  font-weight: $semibold;
  font-size: $base-size * 1.5;
  line-height: 32px;
  letter-spacing: 0;
}

@mixin title-2 {
  font-family: $title-typeface;
  font-weight: $semibold;
  font-size: $base-size * 1.25;
  line-height: 28px;
  letter-spacing: 0;
}

@mixin title-3 {
  font-family: $title-typeface;
  font-weight: $semibold;
  font-size: $base-size * 1.125;
  line-height: 26px;
  letter-spacing: 0;
}

@mixin subtitle-1 {
  font-family: $default-typeface;
  font-weight: $bold;
  font-size: $base-size * 1;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin subtitle-2 {
  font-family: $default-typeface;
  font-weight: $bold;
  font-size: $base-size * 0.875;
  line-height: 22px;
  letter-spacing: 0;
}

@mixin body-1 {
  font-family: $default-typeface;
  font-weight: $regular;
  font-size: $base-size * 1;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin body-2 {
  font-family: $default-typeface;
  font-weight: $regular;
  font-size: $base-size * 0.875;
  line-height: 22px;
  letter-spacing: 0;
}

@mixin body-3 {
  font-family: $default-typeface;
  font-weight: $regular;
  font-size: $base-size * 0.75;
  line-height: 18px;
  letter-spacing: 0;
}

@mixin label {
  font-family: $default-typeface;
  font-weight: $semibold;
  font-size: $base-size * 0.875;
  line-height: 22px;
  letter-spacing: 0;
}

// Component styles

@mixin button-text {
  font-family: $default-typeface;
  font-weight: $semibold;
  font-size: $base-size * 1;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin status-label-text {
  font-family: $default-typeface;
  font-weight: $semibold;
  font-size: $base-size * 0.75;
  line-height: 16px;
  letter-spacing: 0;
}

@mixin badge-text {
  font-family: $default-typeface;
  font-weight: $semibold;
  font-size: $base-size * 0.875;
  line-height: 16px;
  letter-spacing: 0;
}
