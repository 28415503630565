@use 'color';
@use 'typography';
@use 'libs/styles/variables/variables';

$cp-dialog-min-height: 280px;
$cp-dialog-max-height: 1440px;
$cp-dialog-surface-padding: 20px;
$cp-dialog-padding: 40px;
$cp-dialog-actions-height: 40px;
$cp-dialog-actions-top-padding: 24px;
$cp-dialog-bottom-offset: $cp-dialog-padding + $cp-dialog-actions-top-padding + $cp-dialog-actions-height;
$cp-dialog-title-font-size: 20px;
$cp-dialog-title-margin: variables.$cp-margin-large;
$cp-dialog-content-font-size: 16px;
$cp-dialog-line-height: 24px;
$cp-dialog-shadow:
  20px 20px 20px color.$button-shadow,
  -7px 15px 11px color.$button-shadow;
$cp-large-dialog-content-max-height: calc(
  100vh - #{$cp-dialog-padding} - #{$cp-dialog-line-height} - #{$cp-dialog-title-margin} - #{$cp-dialog-bottom-offset}
);

.cp-dialog {
  &__paragraph {
    margin-bottom: variables.$cp-margin-large;
  }

  .mat-mdc-dialog-container {
    font-family: variables.$cp-font-family-primary;
    border-radius: variables.$cp-margin-small;
    box-shadow: $cp-dialog-shadow; /* stylelint-disable-line */
    position: relative;
    min-height: $cp-dialog-min-height;
    max-height: $cp-dialog-max-height;

    .mat-mdc-dialog-title {
      margin: 0 0 $cp-dialog-title-margin;
      padding: 0 $cp-dialog-padding;
      color: color.$pebble-1000;
      font-family: variables.$cp-font-family-secondary;
      font-size: $cp-dialog-title-font-size;
      line-height: $cp-dialog-line-height;
      font-weight: variables.$cp-font-weight-semibold;
      letter-spacing: 0.15px;

      &::before {
        height: initial;
      }
    }

    .mat-mdc-dialog-content {
      margin: 0;
      padding: 0 $cp-dialog-padding;
      font-family: variables.$cp-font-family-primary;
      font-size: $cp-dialog-content-font-size;
      line-height: $cp-dialog-line-height;
      letter-spacing: 0.4px;
      color: color.$pebble-800;
      overflow-y: overlay;
      @include typography.body-1;
    }

    .mat-mdc-dialog-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      padding: $cp-dialog-actions-top-padding $cp-dialog-padding $cp-dialog-padding;
      margin-bottom: 0;

      button[ui-button]:not(:first-of-type),
      button:not(:first-of-type) {
        margin-left: variables.$cp-margin-large;
      }
    }

    .mdc-dialog {
      &__surface {
        padding: $cp-dialog-padding 0 $cp-dialog-bottom-offset;
      }
    }
  }

  &--large {
    .mat-mdc-dialog-container {
      max-height: 100vh;
      border-radius: 0;

      .mat-dialog-content {
        max-height: $cp-large-dialog-content-max-height;
      }
    }
  }
}

.cp-dialog-overlay {
  background: rgba(color.$pebble-1000, 0.8); /* stylelint-disable-line */
}
