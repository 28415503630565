@forward './moyo';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'node_modules/bootstrap/scss/_functions'; /* stylelint-disable-line scss/at-import-no-partial-leading-underscore */

// 2. Include any default variable overrides here: None

// 3. Include remainder of required Bootstrap stylesheets
@import 'node_modules/bootstrap/scss/variables';

$custom-spacers: (
  // Add custom spacer '32' for 2rem or 32px
  32: ($spacer * 2),
  // Add custom spacer '40' for 2.5rem or 40px
  40: ($spacer * 2.5)
);
$spacers: map-merge($spacers, $custom-spacers); // stylelint-disable scss/no-global-function-names

// 4. Include any optional Bootstrap components as you like
@import '../../node_modules/bootstrap/scss/bootstrap-grid';
@import '../../node_modules/bootstrap/scss/bootstrap-utilities';
@import '../../node_modules/bootstrap/scss/carousel';

@import 'ngx-toastr/toastr';

@import './material-theme';

@import '../ui/src/lib/action-footer/action-footer';
@import '../ui/src/lib/action-menu/action-menu';
@import '../ui/src/lib/autocomplete/autocomplete';
@import '../ui/src/lib/input/input';
@import '../ui/src/lib/table/table';
@import '../ui/src/lib/table/paginator/paginator';
@import '../ui/src/lib/table/filters/filters';
@import '../ui/src/lib/checkbox/checkbox';
@import '../ui/src/lib/button-toggle/button-toggle.component';
@import '../ui/src/lib/card/card';
@import '../ui/src/lib/banner/deprecated/double-banner';
@import '../ui/src/lib/form/form';
@import '../ui/src/lib/foundations/icon/icon';
@import '../ui/src/lib/list/list';
@import '../ui/src/lib/toast/toast';
@import '../ui/src/lib/dialog/dialog';
@import '../ui/src/lib/dialog/filter-panel-dialog/filter-panel-dialog';
@import '../ui/src/lib/overlay/overlay';
@import '../ui/src/lib/label/label';
@import '../ui/src/lib/tooltip/tooltip';
@import '../ui/src/lib/loader/loader';
@import '../ui/src/lib/radio-group/radio-group';
@import '../ui/src/lib/autocomplete-multiselect/autocomplete-multiselect-panel';
@import '../ui/src/lib/separator/separator';
@import '../ui/src/lib/tabs/tabs';
@import '../ui/src/lib/textarea/textarea';
@import '../ui/src/lib/selection-list/selection';
@import '../ui/src/lib/stepper/stepper';
@import './color/color';
@import '../ui/src/lib/tooltips/tooltips';
@import '../ui/src/lib/foundations/icon/icon.component';
