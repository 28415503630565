@use 'typography';
@use 'color';
@use 'libs/styles/variables/variables';

$cp-table-row-border: 1px solid color.$pebble-200;
$cp-table-header-row-border: 1px solid color.$pebble-400;

@mixin icon-sort-after {
  &::after {
    content: '';
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    display: flex;
    margin: 0.3rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 125%;
    @content;
  }
}

.cp-table {
  .mat-mdc-header-cell {
    position: relative;
    overflow: visible;
  }

  .mat-mdc-header-cell,
  .mat-mdc-footer-cell,
  .mat-mdc-cell {
    @include typography.body-2;

    padding: 0.875rem 1rem;
    border-bottom-style: none;
    align-self: center;

    &.cp-table__cell {
      &--centered {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      &--numeric {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      &--numeric-sort {
        padding-right: 2.5rem;
      }

      &--action {
        max-width: 2.25rem;
        align-items: center;
        justify-content: center;
        padding: 0;
        overflow: visible;

        cp-action-menu {
          position: relative;
          top: -0.325rem;
          right: 0.75rem;
        }
      }

      &--expanded {
        display: flex;
        justify-content: space-around;
        border-bottom: $cp-table-row-border;
        width: 100%;
      }
    }
  }

  .mat-mdc-header-cell,
  .mat-mdc-footer-cell {
    @include typography.subtitle-2;

    color: color.$pebble-1000;
  }

  .mat-mdc-cell {
    @include typography.body-2;

    color: color.$pebble-800;
    display: block;
  }

  .mat-mdc-row,
  .mat-mdc-header-row,
  .mat-mdc-footer-row {
    min-height: 1.375rem;

    &.cp-table__mat-mdc-footer-cell {
      &--total {
        background-color: color.$pebble-100;
        border-top: none;
        border-bottom: none;
        color: color.$pebble-1000;
      }
    }

    &.cp-table__row {
      &--clickable:hover {
        cursor: pointer;
        background-color: color.$pebble-200;
      }

      &--expanded {
        overflow: hidden;
        border-bottom: 0;
      }

      &--selected {
        background-color: color.$primary-100;
      }

      &--list {
        mat-cell {
          display: flex;
          align-self: flex-start;

          .cp-table__list {
            list-style-type: none;
            margin-left: 0;
          }

          .cp-table__list-item {
            &:not(:first-child) {
              margin-top: 1.5rem;
            }
          }
        }
      }

      &--disabled {
        mat-cell {
          color: color.$pebble-600;
        }
      }
    }
  }

  .mat-mdc-row {
    border-top: 0;
    border-bottom: $cp-table-row-border;
    position: relative;
  }

  .mat-mdc-header-row,
  .mat-mdc-footer-row {
    border-top: $cp-table-header-row-border;
    border-bottom: $cp-table-header-row-border;
  }

  mat-header-cell[mat-sort-header] {
    .mat-sort-header-container {
      @include icon-sort-after {
        background-image: url('/assets/icons/sort.svg');
        opacity: 0.5;
      }
    }

    &[aria-sort='ascending'] {
      .mat-sort-header-container {
        @include icon-sort-after {
          background-image: url('/assets/material-overrides/sort-ascending.svg');
          opacity: 1;
        }
      }
    }

    &[aria-sort='descending'] {
      .mat-sort-header-container {
        @include icon-sort-after {
          background-image: url('/assets/material-overrides/sort-descending.svg');
          opacity: 1;
        }
      }
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  .mat-sort-header-container {
    position: relative;

    &.mat-sort-header-sorted::before {
      opacity: 1;
    }
  }

  .mat-sort-header-arrow {
    display: none;
  }

  &--compact {
    .mat-mdc-row,
    .mat-mdc-header-row,
    .mat-mdc-footer-row {
      min-height: 1.5rem;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell,
    .mat-mdc-footer-cell {
      padding: 0.5rem 1rem;
    }
  }

  &--no-borders {
    .mat-mdc-row,
    .mat-mdc-header-row,
    .mat-mdc-footer-row {
      border-bottom: 0;
      border-top: 0;
    }
  }
}
