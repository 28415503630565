@use 'color';
@use 'libs/styles/variables/variables';

$cp-separator-width: 1px;
$cp-separator-height: 20px;

.cp-separator {
  display: block;
  width: $cp-separator-width;
  height: $cp-separator-height;
  margin: 0 variables.$cp-margin-small;
  background-color: color.$pebble-400;
}
