@use 'color';

:host {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ui-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 100ms ease-in-out;

  /*
   * Parents can override the icon color by setting the --ui-icon-color variable.
   * If no color is set, the default color is pebble-800.
   * Eg: `.icon:hover { --ui-icon-color: red; }`
   */
  color: var(--ui-icon-color, color.$pebble-800);

  @each $name, $value in color.$colors {
    &--color-#{$name} {
      --ui-icon-color: #{$value};
    }
  }

  &__chip {
    &--space-between {
      padding-right: 0.5rem;
    }
  }
}
