@use 'color';
@use 'typography';

.cp-tabs {
  border-bottom: 1px solid color.$pebble-400;

  .mdc-tab {
    @include typography.body-1;

    text-decoration: none;
    height: 3rem;
    padding: 0 1.5rem;

    .mdc-tab__content .mdc-tab__text-label {
      color: color.$pebble-800;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-top-width: 0.25rem;
    }

    &__ripple {
      display: none;
    }

    &--active {
      @include typography.button-text;

      .mdc-tab__content .mdc-tab__text-label {
        color: color.$pebble-1000;
      }
    }

    &:hover {
      .mdc-tab__content .mdc-tab__text-label {
        color: color.$pebble-1000;
      }
    }

    &.cp-tab--with-suffix {
      padding-right: 0.5rem;
    }
  }

  &.cp-tabs--no-border {
    border: none;
  }
}
