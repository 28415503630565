@use 'color';
@use 'typography';
@use 'libs/styles/variables/variables';
@use 'libs/ui/src/lib/input/input.variables';

.cp-radio-group {
  display: block;
  margin-bottom: variables.$cp-margin-large;

  mat-label {
    @include typography.body-3;

    color: input.$cp-input-color-assistive;
    display: flex;
    margin-bottom: variables.$cp-margin-extra-small;
  }

  .mat-radio-label {
    @include typography.body-1;
  }

  .mat-error {
    display: none;
  }

  &--vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .mat-radio-button {
      margin: 0 0 variables.$cp-margin-small;
    }
  }

  .mat-radio-button {
    margin: 0 variables.$cp-margin-huge 0 0;

    .mat-radio-outer-circle {
      border: 1px solid color.$pebble-600;
      box-shadow: inset 0 0 5px rgba(color.$pebble-1000, 0.2); /* stylelint-disable-line */
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border: 2px solid color.$primary-400;
        box-shadow: none; /* stylelint-disable-line */
      }

      .mat-radio-inner-circle {
        transform: scale(0.575);
      }
    }

    &.mat-radio-disabled {
      cursor: not-allowed;

      .mat-radio-label {
        cursor: not-allowed;
      }

      .mat-radio-outer-circle {
        cursor: not-allowed;
        background-color: color.$pebble-200;
        box-shadow: none; /* stylelint-disable-line */
        border: none;
      }

      &.mat-radio-checked {
        .mat-radio-outer-circle {
          background-color: color.$pebble-0;
          border: 2px solid color.$pebble-600;
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.ng-invalid:not(.ng-untouched) {
    mat-label {
      color: color.$blush-600;
    }

    .mat-radio-button:not(.mat-radio-disabled) {
      .mat-radio-outer-circle {
        border-color: color.$blush-600;
      }

      .mat-radio-inner-circle {
        background-color: color.$blush-600;
      }
    }

    .mat-error {
      margin-top: 2px;
      display: block;
    }
  }
}
