@use 'color';
@use 'typography';
@use 'libs/ui/src/lib/input/input.variables';

$cp-checkbox-border-width: 1px;
$cp-checkbox-assistive-height: 24px;

.cp-checkbox-group {
  mat-label,
  mat-hint {
    font-size: input.$cp-input-font-size-assistive;
    height: $cp-checkbox-assistive-height;
    display: flex;
    color: input.$cp-input-color-assistive;
    font-family: input.$cp-input-font-family;
  }
}

.cp-checkbox,
.cp-checkbox-group mat-mdc-checkbox {
  .mdc-checkbox__checkmark-path {
    fill: transparent;
  }

  &.mat-mdc-checkbox {
    &.mat-accent {
      --mdc-checkbox-selected-checkmark-color: #{color.$pebble-0};
    }

    .mdc-checkbox {
      .mdc-checkbox__background {
        border-width: $cp-checkbox-border-width;
      }
    }

    label {
      @include typography.body-1;

      color: color.$pebble-800;
    }

    &.mat-mdc-checkbox-disabled {
      pointer-events: initial;

      .mdc-checkbox__ripple {
        display: none;
      }

      .mdc-checkbox {
        cursor: not-allowed;
      }

      label {
        cursor: not-allowed;
        color: color.$pebble-400;
      }
    }
  }

  &--full-width {
    width: 100%;
  }

  // TODO: https://carepay.atlassian.net/browse/PSAP-292
  // Currently this is being used by the ui-autocomplete-multiselect. Refactor so that this is not needed anymore
  &--truncated {
    &.mat-mdc-checkbox {
      // stylelint-disable-next-line no-descending-specificity
      label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0;
      }

      .mdc-form-field {
        width: 100%;
      }
    }
  }
}
