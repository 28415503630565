@use 'typography';
@use 'color';
@use 'elevation';
@use 'libs/styles/variables/variables';

.ui-filter-panel-dialog {
  --mdc-dialog-supporting-text-color: #{color.$pebble-800};

  .mat-mdc-dialog-container {
    @include typography.body-1;

    .mat-mdc-dialog-title {
      @include typography.title-2;

      margin: 2.5rem 0 1.5rem 1.5rem;
    }

    .mat-mdc-dialog-content {
      max-height: 100vh;
      padding: 0;
    }

    .mat-mdc-dialog-actions {
      display: flex;
      justify-content: space-evenly;

      button[ui-button] {
        width: 10.5rem;
      }
    }
  }
}

.ui-filter-panel-dialog-overlay {
  background-color: rgb(color.$pebble-1000, 0.3); /* stylelint-disable-line */
}

.ui-form-panel-dialog {
  .mat-mdc-dialog-container {
    @include typography.body-1;

    .mdc-dialog__surface {
      padding-top: variables.$cp-margin-large;
    }

    .mat-mdc-dialog-title {
      @include typography.title-2;

      --mdc-dialog-subhead-color: color.$pebble-1000;

      padding: 0 variables.$cp-margin-large;
      padding-bottom: variables.$cp-margin-large;

      &::before {
        height: initial;
      }
    }

    .mat-mdc-dialog-content {
      @include typography.body-1;

      max-height: 100vh;
      padding: variables.$cp-margin-medium variables.$cp-margin-large;
    }

    .mdc-floating-label {
      --mdc-outlined-text-field-label-text-color: color.$pebble-600;
    }

    .mat-mdc-dialog-actions {
      @include elevation.level-2;

      padding: variables.$cp-margin-large;
      border-top: 1px solid color.$pebble-200;

      button[ui-button]:not(:first-of-type),
      button:not(:first-of-type) {
        margin-left: variables.$cp-margin-large;
      }
    }
  }
}
