@use 'color';
@use 'libs/styles/variables/variables';

/* Loaded globally to ensure index.html has this styling available before app loads */

$loader-size: 4rem;

.ui-loader {
  &__container {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    /* Overlay */
    &::before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 10%);
    }
  }

  &__spinner {
    animation: spin 800ms linear infinite;
    position: absolute;
    border: 5px solid color.$pebble-200;
    border-top: 5px solid color.$pebble-600;
    border-radius: 50%;
    left: calc(50% - #{$loader-size * 0.5});
    top: calc(50% - #{$loader-size * 0.5});
    width: $loader-size;
    height: $loader-size;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
