@use 'color';
@use 'libs/styles/variables/variables';
@use '../checkbox/checkbox.variables';
@use '../option/option.mixin';

$list-item-height: 48px;

.cp-list {
  width: 100%;
  position: relative;
  overflow: auto;

  .mat-mdc-list-option {
    --mdc-list-list-item-selected-container-color: #{color.$primary-100};

    .mdc-checkbox {
      --mdc-checkbox-selected-checkmark-color: #{color.$pebble-0};

      .mdc-checkbox__background {
        border-width: 1px;
      }
    }

    .mdc-checkbox::after {
      top: 3.4px;
      left: 2px;
    }
  }

  .mdc-list-item__primary-text {
    --mdc-list-list-item-label-text-font: #{'Inter', sans-serif};
    --mdc-list-list-item-label-text-line-height: #{24px};
    --mdc-list-list-item-label-text-size: #{16px};
    --mdc-list-list-item-label-text-weight: #{400};
    --mdc-list-list-item-label-text-tracking: #{0};
  }

  &.mdc-list {
    padding: 0;
  }

  &.mat-mdc-list-base {
    .cp-list__content {
      @include option.cp-option;

      &--no-truncate {
        height: auto;
        min-height: $list-item-height;

        .mdc-list-item__content {
          .cp-list__text {
            white-space: break-spaces;
            overflow: visible;
          }
        }
      }

      &--no-min-height {
        height: auto;
        min-height: auto;
      }

      &--bullet {
        .mdc-list-item__content {
          &::before {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            position: absolute;
            height: 100%;
            left: 0;
            content: '•';
          }
        }
      }

      &--collapsable {
        position: initial;

        .mdc-list-item__content {
          position: relative;

          &::after {
            transition: transform variables.$cp-transition-base;
            transform: translateY(-50%);
            content: '';
            position: absolute;
            height: 16px;
            width: 16px;
            top: 50%;
            right: variables.$cp-margin-large;
            background-image: url('/assets/icons/chevron-right.svg');
            background-size: 100%;
          }
        }

        &[aria-selected='true'] {
          .mdc-list-item__content {
            &::after {
              transform: rotate(90deg) translateX(-50%);
            }
          }
        }
      }

      &--clickable,
      &--collapsable,
      &.mat-mdc-list-option {
        cursor: pointer;
        transition: background variables.$cp-transition-base;

        &::before {
          position: absolute;
          background-color: transparent;
          content: '';
          height: $list-item-height;
          left: 0;
          right: 0;
          z-index: 0;
        }

        &[aria-selected='true'] {
          &::before {
            background-color: color.$primary-100;
          }
        }

        &:hover,
        &:focus {
          &::before {
            background-color: color.$pebble-200;
          }
        }
      }
    }
  }

  &--bordered {
    border: 1px solid color.$pebble-600;
    border-radius: variables.$cp-border-radius;
  }

  &--nested {
    position: initial;

    &.mat-mdc-list-base {
      width: 100%;

      .mdc-list {
        padding: 0 0 0 variables.$cp-margin-medium;
        overflow: hidden;
      }

      // stylelint-disable-next-line no-descending-specificity
      .cp-list__content--clickable,
      .cp-list__content--collapsable {
        // stylelint-disable-next-line no-descending-specificity
        .mdc-list-item__content {
          padding: 0 60px 0 variables.$cp-margin-medium;
        }
      }
    }
  }
}
