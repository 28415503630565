@use 'color';
@use 'libs/styles/variables/variables';

.cp-side-panel-overlay {
  background: color.$pebble-0;
  position: absolute !important; /* stylelint-disable-line declaration-no-important */
  top: 0;
  bottom: 0;
  right: 0;
  margin-right: 0;
  overflow-y: auto;
  transition: margin-right 0.3s;
  z-index: 101;
  min-height: 100%;

  &__content {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  &__title {
    padding: 8px 16px 20px;
    flex: 1;
    font-weight: variables.$cp-font-weight-semibold;
    font-size: 16px;
    line-height: 24px;
  }

  &__radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
  }

  &--radio-dates {
    align-items: center;
    padding: 12px 16px;
  }

  input[type='radio'] {
    width: 16px !important; /* stylelint-disable-line declaration-no-important */
    height: 16px !important; /* stylelint-disable-line declaration-no-important */
  }

  input[type='radio']:checked {
    background: color.$primary-400;
  }

  &--header {
    display: flex;

    .cp-side-panel-overlay__title {
      padding: 8px 16px 20px;
      flex: 1;
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__content-right {
    width: 320px;
    border-right: 1px solid color.$pebble-200;
    padding: 4px;

    &--filter-types {
      display: grid;
      height: 720px;
      overflow-y: auto;

      mat-checkbox {
        padding: 12px 16px;
        display: flex;
      }
    }
  }

  &__content-left {
    width: 320px;

    &--close {
      position: absolute;
      bottom: 24px;
      align-items: center;
      width: 320px;

      button[ui-button],
      button {
        width: 272px;
        margin-left: variables.$cp-margin-large;
        margin-right: variables.$cp-margin-large;
      }
    }

    &--filter-options {
      padding: 12px 16px;
      border-top: 1px solid color.$pebble-200;

      cp-badge {
        float: right;
      }
    }

    &--filter-options:hover {
      background-color: color.$primary-100;
      border-top: none;
    }
  }
}

.cp-overlay-side-panel-overlay {
  background: rgba(color.$pebble-1000, 0.3); /* stylelint-disable-line */
}
