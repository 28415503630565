@use 'abstracts/typography';
@use 'abstracts/color';

// TODO: Replace with fontsource like in health-hub repo
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600&display=swap';

// TODO: Move this out of typography and into a separate file
:root {
  @include typography.body-1;

  body {
    margin: 0;
    color: color.$pebble-1000;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 0;
    margin: 0;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: color.$pebble-800;
  }
}

.title-1 {
  @include typography.title-1;
}

.title-2 {
  @include typography.title-2;
}

.title-3 {
  @include typography.title-3;
}

.subtitle-1 {
  @include typography.subtitle-1;
}

.subtitle-2 {
  @include typography.subtitle-2;
}

.body-1 {
  @include typography.body-1;
}

.body-2 {
  @include typography.body-2;
}

.body-3 {
  @include typography.body-3;
}

.label {
  @include typography.label;
}

.button-text {
  @include typography.button-text;
}
