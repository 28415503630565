@use 'color';
@use 'libs/styles/variables/variables';

@mixin cp-option {
  font-family: variables.$cp-font-family-primary;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mdc-list-item__primary-text,
  .mat-option-text {
    color: color.$pebble-800;
    display: block;
    margin: 0.75rem 0;
  }

  .mat-option-text,
  .mdc-list-item__content,
  .mdc-list-item__content .mdc-list-item__primary-text {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .cp-list__text,
    .cp-option__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: variables.$cp-margin-small;
      z-index: 1;

      &--strong {
        font-weight: variables.$cp-font-weight-bold;
      }

      &--primary {
        color: color.$primary-400;
      }

      &--secondary {
        font-size: 12px;
        color: color.$pebble-600;
      }

      &--disabled {
        color: color.$pebble-400;
      }
    }
  }
}
