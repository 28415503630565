@use 'color';
@use 'libs/styles/moyo/abstracts/typography';

.cp-button-toggle-group {
  border: 1px solid;

  --mat-standard-button-toggle-divider-color: #{color.$pebble-600};

  .mat-button-toggle {
    --mat-standard-button-toggle-selected-state-text-color: #{color.$pebble-800};

    &-disabled {
      --mat-standard-button-toggle-selected-state-background-color: #{color.$pebble-100};
      --mat-standard-button-toggle-selected-state-text-color: #{color.$pebble-600};
    }

    &-checked {
      --mat-standard-button-toggle-selected-state-background-color: #{color.$primary-800};
      --mat-standard-button-toggle-selected-state-text-color: #{color.$pebble-0};
    }

    &-checked.mat-button-toggle-disabled {
      --mat-standard-button-toggle-selected-state-background-color: #{color.$pebble-400};
      --mat-standard-button-toggle-selected-state-text-color: #{color.$pebble-0};
    }

    > .mat-button-toggle-button {
      padding: 0.75rem 1.5rem;
    }

    .icon-with-text {
      padding-right: 1.2rem;
    }

    .mat-button-toggle-label-content {
      line-height: 1.5rem;
      padding: 0;
      font-weight: typography.$semibold;
    }

    &:hover:not(&-checked) {
      .mat-button-toggle-label-content {
        color: color.$primary-400;
      }
    }
  }
}
