@use 'color';
@use 'libs/styles/variables/variables';
@use 'libs/ui/src/lib/input/input.variables';

.cp-input {
  margin-bottom: variables.$cp-margin-small;
  margin-top: input.$cp-input-margin-top;

  &.mat-mdc-form-field {
    @include input.cp-input;

    width: 100%;
  }

  &--compact.mat-mdc-form-field {
    @include input.cp-input-compact;
  }

  &--no-borders.mat-mdc-form-field.mat-mdc-form-field-appearance-outline {
    @include input.cp-input-no-borders;
  }

  &--small {
    &.mat-mdc-form-field {
      @include input.cp-input-small;
    }
  }

  &--medium {
    &.mat-mdc-form-field {
      @include input.cp-input-medium;
    }
  }

  &--tiny {
    &.mat-mdc-form-field {
      @include input.cp-input-tiny;
    }
  }
}

.mat-mdc-error {
  font-size: input.$cp-input-font-size-assistive;
  color: color.$blush-600;
}

/**
 Override for \portals\payer-portal\src\styles\components\_form-inputs.scss
 When all inputs are replaced by cp-input this can be removed
 */
input[type='number'],
input[type='number']:disabled,
input[type='number']:read-only {
  background: none;
  border: none;
}
