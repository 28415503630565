@mixin level-0 {
  box-shadow: none;
}

@mixin level-1 {
  box-shadow: 0 2px 2px rgba(0 0 0 / 20%);
}

@mixin level-2 {
  box-shadow: 0 2px 12px rgba(0 0 0 / 25%);
}

@mixin level-3 {
  box-shadow: 0 4px 24px rgba(0 0 0 / 20%);
}

// Add new subtle shadow mixin
@mixin subtle-shadow {
  box-shadow: 0 10px 20px rgba(0 0 0 / 3%);
}
