@use 'color';
@use 'libs/styles/variables/variables';

$cp-card-header-height: 60px;
$cp-card-toolbar-height-without-borders: 70px;
$cp-card-actions-height: 60px;
$cp-card-header-horizontal-padding: variables.$cp-margin-huge;
$cp-card-content-padding: variables.$cp-margin-medium;
$cp-card-actions-button-margin: variables.$cp-margin-medium;

.cp-card {
  &.mat-mdc-card {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: variables.$cp-margin-medium;
  }

  .mat-mdc-card-header {
    display: flex;
    align-items: center;
    background: color.$pebble-0;
    color: color.$pebble-800;
    height: $cp-card-header-height;
    border-bottom: 1px solid color.$pebble-200;

    .mat-mdc-card-header-text {
      margin-left: $cp-card-header-horizontal-padding;

      button[ui-icon] {
        margin-right: variables.$cp-margin-small;
      }
    }

    .cp-card__header-actions {
      height: 100%;
      margin-left: auto;
      margin-right: $cp-card-header-horizontal-padding;
      display: flex;
      align-items: center;

      button[ui-icon] {
        margin-left: variables.$cp-margin-small;
      }
    }

    .mat-mdc-card-title {
      display: flex;
      margin: 0;
      align-items: center;
      font-size: 20px;
      line-height: 24px;
      font-weight: variables.$cp-font-weight-semibold;
      font-family: variables.$cp-font-family-secondary;
      letter-spacing: 0.15px;
    }
  }

  .mat-mdc-card-content {
    padding: $cp-card-content-padding $cp-card-content-padding $cp-card-toolbar-height-without-borders;
    margin: 0;
  }

  .mat-mdc-card-actions {
    position: absolute;
    right: 0;
    bottom: variables.$cp-margin-small;
    margin: 0;
    height: $cp-card-actions-height;
    display: flex;
    align-items: center;
    padding: 0 $cp-card-content-padding;
    justify-content: flex-end;
    width: 100%;

    button[ui-button] {
      margin-left: $cp-card-actions-button-margin;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--compact {
    .mat-mdc-card-content {
      padding: 0;
    }
  }
}
