@use 'color';
@use 'elevation';
@use 'libs/styles/variables/variables';

$cp-action-menu-trigger-size: 1.75rem;
$cp-action-menu-width: 10rem;

.cdk-overlay-container {
  z-index: 1005;
}

.cdk-overlay-container .cdk-overlay-pane {
  z-index: 1005;
}

cp-action-menu {
  display: block;
  height: $cp-action-menu-trigger-size;
  position: absolute;
  text-align: end;
  right: variables.$cp-margin-small;
  top: variables.$cp-margin-small;
}

.cp-action-menu {
  @include elevation.level-2;

  &.mat-mdc-menu-panel {
    min-height: 0;

    &.mat-mdc-menu-panel {
      min-width: $cp-action-menu-width;
    }
  }

  &__trigger {
    cursor: pointer;
    border: none;
    padding: 0;
    height: $cp-action-menu-trigger-size;
    width: $cp-action-menu-trigger-size;
    border-radius: 100%;
    background-color: transparent;

    &:disabled {
      cursor: not-allowed;
    }

    &--dots {
      &:hover:not(:disabled) {
        background-color: color.$pebble-200;
      }
    }
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}
