@use '../moyo/abstracts/color';

.cp-color {
  &--dark-grey {
    color: color.$pebble-600;
  }

  &--grey {
    color: color.$pebble-400;
  }

  &--warn {
    color: color.$sunset-600;
  }

  @each $color, $value in color.$colors {
    &--#{$color} {
      color: $value;
    }
  }
}

.ui-background-color {
  @each $color, $value in color.$colors {
    &--#{$color} {
      background-color: $value;
    }
  }
}
