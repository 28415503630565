@use 'libs/styles/variables/variables';

$cp-form-width: 34.5rem;

.cp-form {
  max-width: $cp-form-width;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button[ui-button] {
      margin-left: variables.$cp-margin-button;
    }

    &--left {
      justify-content: flex-start;

      button[ui-button] {
        margin: 0 variables.$cp-margin-button 0 0;
      }
    }
  }

  &--horizontal {
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: variables.$cp-margin-medium;

    .cp-input {
      margin-right: variables.$cp-margin-medium;
    }
  }

  &--wrap {
    flex-wrap: wrap;

    .cp-form__actions {
      button[ui-button] {
        margin-left: 0;
      }
    }
  }
}
