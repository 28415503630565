@use 'typography';
@use 'color';

.cp-label {
  @include typography.status-label-text;

  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  border-radius: 4px;
  padding: 0 0.5rem;

  &--actionable {
    color: color.$cobalt-600;
    background: color.$cobalt-100;
  }

  &--non-actionable {
    color: color.$pebble-1000;
    background: color.$pebble-200;
  }

  &--positive {
    color: color.$moss-600;
    background: color.$moss-100;
  }

  &--negative {
    color: color.$blush-600;
    background: color.$blush-100;
  }

  &--warning {
    color: color.$sunset-600;
    background: color.$sunset-100;
  }

  &--returned {
    color: color.$grape-600;
    background: color.$grape-100;
  }

  &--archived {
    color: color.$pebble-600;
    background: color.$pebble-200;
  }

  &--scheduled {
    color: color.$cobalt-600;
    background: color.$cobalt-100;
  }

  &--informational {
    color: color.$frost-600;
    background: color.$frost-100;
  }
}
