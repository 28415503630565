.cp-double-banner {
  display: flex;

  cp-banner:first-child > .cp-banner {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    min-height: 100%;
  }

  cp-banner:last-child > .cp-banner {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    min-height: 100%;
  }
}
