@use 'color';
@use 'libs/styles/variables/variables';
@use 'libs/styles/moyo/abstracts/typography';

$cp-step-icon-size: 32px;

.mat-stepper-horizontal.ui-stepper {
  .ui-stepper__header {
    font-size: typography.$base-size;
  }
}

.mat-stepper-vertical.ui-stepper {
  &__header {
    font-weight: variables.$cp-font-weight-semibold;
  }

  .mat-vertical-content-container {
    margin-left: 27px;
  }

  .mat-step-header {
    padding: 12px;
    margin-bottom: 8px;
    border-radius: variables.$cp-border-radius;

    &[aria-selected='false'] {
      &:hover {
        background-color: color.$pebble-100;
        cursor: pointer;

        .mat-step-label {
          .ui-stepper__header {
            color: color.$primary-400;
          }
        }
      }

      ~ .mat-stepper-vertical-line::before {
        border-left-width: 0;
      }
    }

    &[aria-selected='true'] {
      &:hover {
        background-color: transparent;
        cursor: default;
      }

      ~ .mat-stepper-vertical-line::before {
        margin: 0 0 8px;
        z-index: 1;
        border-color: color.$pebble-400;
      }
    }

    &[aria-disabled='true'] {
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }

    .mat-step-optional {
      display: none;
    }

    .mat-step-icon {
      height: $cp-step-icon-size;
      width: $cp-step-icon-size;
      font-family: variables.$cp-font-family-primary;
      font-weight: variables.$cp-font-weight-semibold;

      &-state-edit {
        background-color: color.$primary-400;
        color: color.$pebble-0;
        border: 1px solid color.$primary-400;
      }

      &-state-done {
        background-color: color.$pebble-600;
        color: color.$pebble-0;
        border: 1px solid color.$pebble-600;

        // stylelint-disable-next-line no-descending-specificity
        + .mat-step-label {
          &.mat-step-label-active {
            .ui-stepper__header {
              color: color.$pebble-600;
            }
          }
        }
      }

      &-state-number {
        background-color: color.$pebble-0;
        border: 1px solid color.$primary-400;
        color: color.$primary-400;
      }

      &-selected {
        background-color: color.$primary-600;
        color: color.$pebble-0;
        border: 1px solid color.$primary-600;
      }

      &.mat-step-icon-state-error {
        background-color: color.$blush-600;
        color: color.$pebble-0;
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    .mat-step-label.mat-step-label-active .ui-stepper__header {
      color: color.$pebble-800;
    }
  }

  .mat-vertical-stepper-content {
    .mat-vertical-content {
      padding-top: variables.$cp-margin-medium;
      padding-left: variables.$cp-margin-giant;
    }
  }

  &.ui-stepper--linear {
    .mat-step-header {
      .mat-step-icon {
        &-state-number:not(.mat-step-icon-selected) {
          background-color: color.$pebble-0;
          border: 1px solid color.$pebble-600;
          color: color.$pebble-600;

          + .mat-step-label {
            .ui-stepper__header {
              color: color.$pebble-600;
            }
          }
        }
      }
    }
  }
}
