@use 'color';

.cp-toast {
  &--stackable {
    background-color: color.$pebble-800;
  }
}

.overlay-container .toast-container {
  z-index: 1002;
}

.overlay-container[aria-hidden='true'] .toast-container,
app-root[aria-hidden='true'] ~ .overlay-container .toast-container {
  z-index: 1010;
}
